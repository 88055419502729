<template>
    <b-container>
        <b-row class="text-center mb-3">
            <b-col cols="12">
                <h1>Atelier <br class="d-lg-none" />massage bébé</h1>
            </b-col>
        </b-row>
        <b-row >
            <b-col xs="12" lg="6">
                <p>
                    Le toucher est l’un des premiers sens que bébé va développer
                    in utéro. Lorsque bébé était dans le ventre de maman, il
                    était déjà initié à vos caresses, bercé entre vos mains. Il
                    n’a pas arrêté d’être en contact permanent avec maman
                    pendant neuf mois, pourquoi ne pas continuer après la
                    naissance ?<br />
                    <br />
                    Lors de cette séance, vous apprendrez à masser tout le corps
                    de votre bébé. Le massage permet de créer le lien
                    parent/bébé, de relâcher les tensions, de créer un sentiment
                    de sécurité et de sérénité. C’est l’occasion de prendre du
                    temps pour vous et votre bébé. Un temps durant lequel vous
                    allez vous relaxer ensemble.<br />
                    <br />
                    <price :price="60" :group="true" />
                </p>
            </b-col>
            <b-col xs="12" lg="6" >
                <non-clickable-img url="massage.jpg" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Price from '../components/Price';
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'Massage',
    components: { Price, NonClickableImg },
};
</script>

<style scoped>
p {
    text-align: justify;
    text-justify: inter-word;
}
</style>
